// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_inputComponente__cCCE4 {
  width: 100%;
  margin-top: 1rem;
  height: 50px;
  border: 0;
  border-radius: 0.5rem;
  background-color: #DCDCDC;
  padding: 1rem;
  border: 1px solid var(--COLORFONTES_GRAY_200);
}

.styles_inputComponente__cCCE4::placeholder {
  color: rgba(59, 57, 57, 0.8);
}

.styles_inputComponenteSelect__byyfn {
  width: 100%;
  margin-top: 1rem;
  height: 50px;
  border: 0;
  border-radius: 0.5rem;
  background-color: #DCDCDC;
  padding: 1rem;
  border: 1px solid var(--COLORFONTES_GRAY_200);
}

.styles_inputComponenteClean__VcZd9 {
  width: 100%;
  height: 38px;
  border: 0;
  padding: 1rem;
  border: 1px solid var(--COLORFONTES_GRAY_200);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/InputFormBit/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;EACA,qBAAA;EACA,yBAAA;EAEA,aAAA;EACA,6CAAA;AAAJ;;AAGA;EACI,4BAAA;AAAJ;;AAGA;EACI,WAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;EACA,qBAAA;EACA,yBAAA;EAEA,aAAA;EACA,6CAAA;AADJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,SAAA;EAIA,aAAA;EACA,6CAAA;AAJJ","sourcesContent":[".inputComponente{\n    width: 100%;\n    margin-top: 1rem;\n    height: 50px;\n    border: 0;\n    border-radius: 0.5rem;\n    background-color: #DCDCDC;\n   // color: var(--COLORFONTES_WHITE);\n    padding: 1rem;\n    border: 1px solid var(--COLORFONTES_GRAY_200);\n}\n\n.inputComponente::placeholder{\n    color: rgba(59, 57, 57, 0.8);\n}\n\n.inputComponenteSelect{\n    width: 100%;\n    margin-top: 1rem;\n    height: 50px;\n    border: 0;\n    border-radius: 0.5rem;\n    background-color: #DCDCDC;\n   // color: var(--COLORFONTES_WHITE);\n    padding: 1rem;\n    border: 1px solid var(--COLORFONTES_GRAY_200);\n}\n\n.inputComponenteClean{\n    width: 100%;\n    height: 38px;\n    border: 0;\n    //border-radius: 0.5rem;\n    //background-color: #DCDCDC;\n   // color: var(--COLORFONTES_WHITE);\n    padding: 1rem;\n    border: 1px solid var(--COLORFONTES_GRAY_200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputComponente": `styles_inputComponente__cCCE4`,
	"inputComponenteSelect": `styles_inputComponenteSelect__byyfn`,
	"inputComponenteClean": `styles_inputComponenteClean__VcZd9`
};
export default ___CSS_LOADER_EXPORT___;
