
interface Props {
    fill?: string;
    largura?: string;
    altura?: string;
}

export function PagamentoIconCheckout({ largura, altura, fill }: Props) {
   
    return (


        <svg xmlns="http://www.w3.org/2000/svg" width={largura != undefined ? largura : "19.993"} height={altura != undefined ? altura : "19.992"} viewBox="0 0 19.993 19.992">
        <g id="arrow-left-right" transform="translate(-3 -3)">
          <g id="Camada_1-2" transform="translate(3 3)">
            <path id="Caminho_661" data-name="Caminho 661" d="M0,68.214a.879.879,0,0,1,.974-.629c.721.02,1.442.005,2.188.005v-.239q0-3.328,0-6.655a.781.781,0,1,1,1.56-.025q0,1.405,0,2.811v4.093H6.169v-.224q0-3.328,0-6.655a.781.781,0,1,1,1.56-.025q0,1.385,0,2.771v4.133H9.175v-.223q0-3.328,0-6.655a.781.781,0,1,1,1.56-.026c0,.9,0,1.8,0,2.693v4.21H12.3v-.223q0-3.328,0-6.655a.781.781,0,1,1,1.56-.026c0,.93,0,1.861,0,2.791v4.113h1.523v-.222q0-3.328,0-6.655a.781.781,0,1,1,1.56-.027c0,.9,0,1.809,0,2.713v4.207h.211q1,0,1.991,0a.783.783,0,1,1,0,1.561q-9.154,0-18.307,0A.827.827,0,0,1,0,68.528v-.312Z" transform="translate(0 -56.737)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_662" data-name="Caminho 662" d="M4.06,0c.029.014.057.028.088.04A.78.78,0,0,1,3.885,1.56c-.715,0-1.43,0-2.145,0H1.561V3.787a.8.8,0,0,1-.648.887.8.8,0,0,1-.895-.579A.268.268,0,0,0,0,4.06V.625A.91.91,0,0,1,.625,0Z" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_663" data-name="Caminho 663" d="M297.431,0a.883.883,0,0,1,.63.973c-.018.975,0,1.95-.007,2.925a.744.744,0,0,1-.455.713.736.736,0,0,1-.806-.1.77.77,0,0,1-.3-.648c0-.689,0-1.378,0-2.067V1.561h-2.246a.8.8,0,0,1-.867-.648.791.791,0,0,1,.582-.892A.183.183,0,0,0,294,0h3.435Z" transform="translate(-278.068)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_664" data-name="Caminho 664" d="M0,294l.052-.125a.787.787,0,0,1,.862-.489.776.776,0,0,1,.646.769c.005.7,0,1.4,0,2.106v.237h.218q1.033,0,2.067,0a.782.782,0,1,1-.007,1.56c-.955,0-1.911-.013-2.866.006a.884.884,0,0,1-.973-.63Q0,295.723,0,294Z" transform="translate(0 -278.077)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_665" data-name="Caminho 665" d="M296.552,296.533c0-.765,0-1.5,0-2.245a1.167,1.167,0,0,1,.033-.308.771.771,0,0,1,.827-.567.763.763,0,0,1,.694.725q.016,1.61,0,3.219a.75.75,0,0,1-.746.733q-1.59.013-3.181,0a.778.778,0,0,1,.022-1.556c.7-.006,1.405,0,2.107,0h.24Z" transform="translate(-278.126 -278.107)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_666" data-name="Caminho 666" d="M62.136,261.953c0,.234,0,.468,0,.7a.779.779,0,0,1-1.558,0q-.01-.692,0-1.383a.779.779,0,0,1,1.558,0c.006.227,0,.455,0,.682Z" transform="translate(-57.413 -246.913)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_667" data-name="Caminho 667" d="M118.238,261.954c0-.227,0-.455,0-.682a.779.779,0,0,1,1.558,0q.011.692,0,1.383a.779.779,0,0,1-1.558,0c-.006-.234,0-.467,0-.7Z" transform="translate(-112.069 -246.913)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_668" data-name="Caminho 668" d="M237.293,261.961c0,.227,0,.455,0,.682a.779.779,0,0,1-1.558,0q-.011-.691,0-1.383a.779.779,0,0,1,1.558,0c.006.234,0,.467,0,.7Z" transform="translate(-223.435 -246.904)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_669" data-name="Caminho 669" d="M177.723,261.961c0,.227,0,.455,0,.682a.779.779,0,0,1-1.558,0q-.011-.691,0-1.383a.779.779,0,0,1,1.558,0c.006.234,0,.467,0,.7Z" transform="translate(-166.971 -246.904)" fill={fill != undefined ? fill : "#0d766e"} />
            <path id="Caminho_670" data-name="Caminho 670" d="M296.441,261.966c0,.227,0,.455,0,.682a.779.779,0,1,1-1.558-.006c-.005-.454-.006-.909,0-1.364a.779.779,0,1,1,1.558.006c0,.227,0,.455,0,.682Z" transform="translate(-279.499 -246.914)" fill={fill != undefined ? fill : "#0d766e"} />
          </g>
        </g>
      </svg>

    );


}

export function CartaoIconCheckout({ largura, altura, fill }: Props) {
  
    return (


        <svg xmlns="http://www.w3.org/2000/svg" width={largura != undefined ? largura : "27.739"} height={altura != undefined ? altura : "27.742"} viewBox="0 0 27.739 27.742">
            <g id="_ëîé_1" transform="translate(-0.004 -0.001)">
                <path id="Caminho_623" data-name="Caminho 623" d="M7.967,27.743c-.356-.19-.474-.37-.434-.666A.551.551,0,0,1,8.1,26.6c.3-.012.61,0,.993,0-.115-.123-.181-.2-.252-.27L.853,18.337A2.451,2.451,0,0,1,.174,15.6a2.894,2.894,0,0,1,.654-1.007Q7.689,7.7,14.576.83a2.517,2.517,0,0,1,3.733,0q4.3,4.3,8.6,8.6A3.058,3.058,0,0,1,27.743,11v.6a3.508,3.508,0,0,1-1.167,1.923q-6.413,6.393-12.809,12.8c-.071.071-.138.147-.251.268h.363q2.788,0,5.576,0a1.22,1.22,0,0,1,.447.069.5.5,0,0,1,.314.495.623.623,0,0,1-.439.586H7.967ZM21.323,5.487c-.091.107-.156.195-.233.271Q14.626,12.224,8.159,18.688a2.006,2.006,0,0,1-.285.247.557.557,0,0,1-.7-.058.55.55,0,0,1-.084-.715,1.861,1.861,0,0,1,.248-.285Q13.824,11.392,20.31,4.91a3.73,3.73,0,0,1,.316-.242,2.183,2.183,0,0,1-.309-.2q-1.383-1.375-2.76-2.756a1.4,1.4,0,0,0-2.237.009q-6.8,6.8-13.6,13.6a3.556,3.556,0,0,0-.258.278,1.326,1.326,0,0,0-.043,1.633c.236.3.525.564.845.9a2.475,2.475,0,0,1,.212-.294Q8.94,11.368,15.406,4.905a2.025,2.025,0,0,1,.306-.265.562.562,0,0,1,.782.765,1.917,1.917,0,0,1-.264.306Q9.758,12.187,3.28,18.659a1.438,1.438,0,0,1-.287.174c.148.154.214.227.284.3l6.9,6.9a1.406,1.406,0,0,0,2.256,0l13.6-13.6a1.4,1.4,0,0,0,.01-2.235Q23.8,7.947,21.555,5.707c-.062-.062-.129-.121-.233-.221Z" transform="translate(0 0)" fill={fill != undefined ? fill : "#0d766e"} />
                <path id="Caminho_624" data-name="Caminho 624" d="M276.608,133.613a2.422,2.422,0,0,1-.463-.272c-.519-.5-1.028-1.012-1.532-1.527a.593.593,0,0,1,0-.928q1.158-1.175,2.333-2.333a.593.593,0,0,1,.946,0q.753.738,1.491,1.491a.6.6,0,0,1,0,.966q-1.141,1.153-2.3,2.293a2.765,2.765,0,0,1-.478.305Zm-.759-2.279.746.749,1.561-1.567-.736-.755-1.572,1.574Z" transform="translate(-255.766 -119.611)" fill={fill != undefined ? fill : "#0d766e"} />
                <path id="Caminho_625" data-name="Caminho 625" d="M127.28,248.755a2.858,2.858,0,0,1,.311-.5q2.191-2.209,4.4-4.4a1.047,1.047,0,0,1,.212-.166.565.565,0,0,1,.774.761,1.24,1.24,0,0,1-.2.252q-2.159,2.164-4.322,4.323c-.07.07-.144.168-.228.183a1,1,0,0,1-.556.009c-.157-.077-.248-.29-.385-.465Z" transform="translate(-118.64 -227.086)" fill={fill != undefined ? fill : "#0d766e"} />
                <path id="Caminho_626" data-name="Caminho 626" d="M153.728,310.224c-.168-.131-.382-.221-.459-.379s-.007-.369,0-.558a.132.132,0,0,1,.043-.067c.644-.645,1.282-1.3,1.937-1.931a.516.516,0,0,1,.675-.043.54.54,0,0,1,.232.635.8.8,0,0,1-.184.293c-.583.592-1.168,1.182-1.764,1.76a2.662,2.662,0,0,1-.483.288Z" transform="translate(-142.832 -286.299)" fill={fill != undefined ? fill : "#0d766e"} />
                <path id="Caminho_627" data-name="Caminho 627" d="M203.049,282.532a1,1,0,0,1-.842.79.582.582,0,0,1-.537-.6,1,1,0,0,1,.843-.789.576.576,0,0,1,.536.6Z" transform="translate(-187.981 -262.798)" fill={fill != undefined ? fill : "#0d766e"} />
                <path id="Caminho_628" data-name="Caminho 628" d="M81.039,284.081a1.008,1.008,0,0,1-.812.821.579.579,0,0,1-.567-.572.992.992,0,0,1,.812-.82.579.579,0,0,1,.568.571Z" transform="translate(-74.251 -264.271)" fill={fill != undefined ? fill : "#0d766e"} />
                <path id="Caminho_629" data-name="Caminho 629" d="M247.41,44.3a1,1,0,0,1,.81-.821.577.577,0,0,1,.569.569.987.987,0,0,1-.81.82A.57.57,0,0,1,247.41,44.3Z" transform="translate(-230.618 -40.528)" fill={fill != undefined ? fill : "#0d766e"} />
            </g>
        </svg>

    );


}

export function PixIconCheckout({ largura, altura, fill }: Props) {
 
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width={largura != undefined ? largura : "20.999"} height={altura != undefined ? altura : "21"} viewBox="0 0 20.999 21">
            <g id="pix" transform="translate(0 0)">
                <path id="Caminho_34" data-name="Caminho 34" d="M4.623,16.065a3.067,3.067,0,0,0,2.181-.9l3.15-3.15a.6.6,0,0,1,.828,0l3.161,3.161a3.066,3.066,0,0,0,2.181.9h.621l-3.99,3.99a3.191,3.191,0,0,1-4.511,0l-4-4Zm11.5-11.141a3.067,3.067,0,0,0-2.181.9L10.782,8.989a.586.586,0,0,1-.828,0L6.8,5.839a3.067,3.067,0,0,0-2.181-.9h-.38l4-4a3.19,3.19,0,0,1,4.511,0l3.989,3.989ZM.935,8.244,3.316,5.862H4.622a2.173,2.173,0,0,1,1.526.632L9.3,9.644a1.514,1.514,0,0,0,2.138,0L14.6,6.482a2.172,2.172,0,0,1,1.526-.633H17.67l2.395,2.395a3.19,3.19,0,0,1,0,4.511L17.671,15.15H16.124a2.172,2.172,0,0,1-1.526-.632l-3.161-3.161a1.549,1.549,0,0,0-2.138,0l-3.15,3.15a2.173,2.173,0,0,1-1.526.632h-1.3L.935,12.756a3.19,3.19,0,0,1,0-4.511" transform="translate(0 0)" fill={fill != undefined ? fill : "#0d766e"} />
            </g>
        </svg>

    );


}


