import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Rotas } from './routes';
//import { AuthProvider } from './providers/auth';
import { CookiesProvider } from 'react-cookie';

import '../src/styles/globals.scss';

function App() {
	return (
		<CookiesProvider>
			<Router>
				<Fragment>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<Rotas />
							<ToastContainer autoClose={3000} />
						</PersistGate>
					</Provider>
				</Fragment>
			</Router>
		</CookiesProvider>
	);
}

export default App;
