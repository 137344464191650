
import { setupAPIClient } from '../../services/api';

const apiClient = setupAPIClient();

export const getOrderData = async (codeOrder: any) => {


	try {
		const response = await apiClient.get(
			`/checkout/order?checkoutCode=${codeOrder}`,
		);
		return response;
	} catch (error: any) {
		return {
			error: error?.response?.status,
			data: error?.response?.data,
			status: error?.response?.status,
		};
	}
};

export const sendPaymentOrder = async (dataPayment: any) => {


	try {
		const response = await apiClient.post(
			`/payments/card`,dataPayment
		);
		return response;
	} catch (error: any) {
		return {
			error: error?.response?.status,
			data: error?.response?.data,
			status: error?.response?.status,
		};
	}
};

export const paymentWithPìx = async (dataPayment: any) => {

	try {
		const response = await apiClient.post(
			//`/pix/cash-in/create-charge`,dataPayment
			`/pix/cash-in/create-charge-order`,dataPayment
		);
		return response;
	} catch (error: any) {
		console.log(error)
		return {
			error: error?.response?.status,
			data: error?.response?.data,
			status: error?.response?.status,
		};
	}
};

export const statusOrder = async (numberOrder: any) => {
	
		try {
			const response = await apiClient.get(
				//`/pix/cash-in/create-charge`,dataPayment
				`/checkout/order/status?checkoutCode=${numberOrder}`
			);
			return response;
		} catch (error: any) {
			console.log(error)
			return {
				error: error?.response?.status,
				data: error?.response?.data,
				status: error?.response?.status,
			};
		}
	};
	
	

