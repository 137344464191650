import { useEffect, FormEvent, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import getPropSegura from '../../utils/getPropSegura';
import { toast } from 'react-toastify';
//@ts-ignore
import { connect } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import styles from './styles/styles.module.scss';
import { Input } from '../../components/ui/Input';
import { Spinner } from '../../components/Spinner/Spinner';
import { Buttom, ButtonOutline } from '../../components/ui/Buttom';
import { InputFormBitClean } from '../../components/ui/InputFormBit';
import { PagamentoIconCheckout, PixIconCheckout, CartaoIconCheckout } from '../../components/MyIcons';

import Invoice from '../../components/Invoice/Invoice'
import { Header } from '../../layout/Header';
import AlertSnack from '../../components/AlertSnack/AlertSnack'
import { virifyCpgCnpj } from '../../validacoes/maskCpfCnpj';
import { } from '../../'
import ImgCarrinho from '../../assets/geral/carrinho-de-compras.png'
import { FaShoppingCart } from "react-icons/fa";
import Cards, { Focused } from 'react-credit-cards'; // Importe o tipo Focused
import CreditCard from '../../components/CreditCard/CreditCard'
import 'react-credit-cards/es/styles-compiled.css';
import { moneyMask } from '../../utils/cpfMask'
import {
	validateCpf,
	validateCnpj,
	telefoneMask,
	cepMask,
	validateCreditCard,
	verificarNomeCompleto,
	numberCardCreditMask,
	verificaEmail
} from '../../validacoes/validarMascaras';
import { useDispatch } from 'react-redux';
import IconsBith from '../../lib/IconsBith/';
import Select from 'react-select';
import { validNumberCreditCard } from '../../validacoes/validCreditCard'
import {
	resetUserRegisterData,
	setUserRegisterField,
} from '../../redux/actions/userRegisterActions';
import * as React from 'react';

import { getAddressByCep } from '../../apis/endereco';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { AlertColor } from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { getOrderData, sendPaymentOrder, paymentWithPìx, statusOrder } from '../../apis/checkOut'

interface ItensProp {
	productCode: string;
	productName: string;
	productSKU: string;
	quantity: string;
	unitPrice: number
}

const steps = [
	'dados pessoais',
	'Pagamento',
];

type dadosSelect = {
	value: number;
	label: string;

};
type dadosInputGFormProps = {
	nomeCompleto: string;
	emailCliente: string;
	cpfCnpj: string;
	telefone: string;
	numeroCartao: string;
	validadeCartao: string;
	cvvCartao: string;
	nomeCartao: string;
	cpfCartao: string;
	parcelaCartao: string;
	cep: string;
	endereco: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	estado: string;
	idAutorizacaoPagamento: string;
	idConfirmacaoPagamento: string;
	formaDePagamento: string;

};

type enderecoEntrgaProps = {

	street: string;
	number: string;
	complement: string;
	neighborhood: string;
	city: string;
	state: string;
	zipCode: string;
	country: string
}

type customer = {

	name: string
	birthdate: string,
	documentType: string,
	documentNumber: string,
	email: string,
	phoneNumber: string,
	cellPhoneNumber: string,
	address: enderecoEntrgaProps

};

interface itensProps {
	productCode: string;
	productName: string;
	productSKU: string;
	quantity: number;
	unitPrice: string

}

interface ordeProps {
	orderCode: string;
	amount: number;
	statusText: string;
	customer: customer;
	items: itensProps

}

interface sellerPrps {
	companyName: string;
}
const Login = (props: any) => {
	//const { signIn } = useContext(AuthContext);
	let contador = 0;
	const isMobile = useMediaQuery('(max-width: 600px)');
	const dispatch = useDispatch();
	const [currentColor, setCurrentColor] = useState('black');
	const [currentText, setCurrentText] = useState('Aguardando...');
	const [nPagina, setNpagina] = useState(1);
	const [numberStep, setNumberStep] = useState(0);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [paymentSucesso, setPaymentSucesso] = useState(false);
	const [typeFormPayment, setTypeFormPayment] = useState('');
	const [menssageAlert, setMenssageAlert] = useState('');
	const [itensOrder, setItensOrder] = useState<itensProps[]>([]);
	const [dataOrder, setDataOrder] = useState<ordeProps>();
	const [mostraPix, setMostraPix] = useState(false);
	const [mostraCredito, setMostraCredito] = useState(false);
	const [dataSeller, setDataSeller] = useState<sellerPrps>()
	const [starStopPix, setStarStopPix] = useState('stop');
	const [totalAmount, setTotalAmount] = useState(0);
	const [retornoSearchOrder, setOetornoSearchOrder] = useState(false);
	const [typeAlert, setTypeAlert] = useState<AlertColor>('success');
	const [typePayment, setTypePayment] = useState('NONE');
	const [habilitaCpfCnpjEdit, setHabilitaCpfCnpjEdit] = useState(true);
	const [openAlert, setOpenAlert] = useState(false);
	const [codOrder, setCodOrder] = useState<any>();
	const [ipClient, setIpClient] = useState('177.55.230.19');
	const [imgQrCode, setImgQrCode] = useState('');
	const [linkCopiaEcola, setLinkCopiaEcola] = useState('');
	const [autorizationId, setAutorizationId] = useState('');
	const [paymentId, setPaymentId] = useState('');
	const [dataPaymentSelect, setDataPaymentSelect] = useState<dadosSelect[]>([])
	const [showMenu, setShowMenu] = useState(isMobile ? false : true);
	const [focus, setFocus] = useState<Focused | undefined>(undefined);
	const [cardInfo, setCardInfo] = useState({
		number: '',
		name: '',
		expiry: '',
		cvc: '',
		focused: undefined,
	});

	const quantityParcela = 4;

	let selecParcelaPagamento: dadosSelect[] = [];

	const {
		allFields,
		nomeCompleto,
		emailCliente,
		cpfCnpj,
		telefone,
		numeroCartao,
		validadeCartao,
		cvvCartao,
		nomeCartao,
		cpfCartao,
		parcelaCartao,
		cep,
		endereco,
		numero,
		complemento,
		bairro,
		cidade,
		estado,
		idAutorizacaoPagamento,
		idConfirmacaoPagamento,
		formaDePagamento,
		setFieldRedux,
		invalidFields,
		resetUserRegisterDataRedux,
	} = props;


	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setCardInfo((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleInputFocus = (dataFocus: any) => {
		console.log(dataFocus)
		setFocus(dataFocus as Focused); // Atualize o estado focus
	};


	const handleValueChange = ({ name, valor, isValid, page }: {
		name: string;
		valor: string; // Add this line to explicitly declare the type
		isValid: boolean;
		page: number;
	}) => {
		setFieldRedux(name, {
			name,
			valor,
			isValid,
			page,
		});
	};



	const {
		control,
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<dadosInputGFormProps>({
		mode: 'onChange',

	});


	const verifyFieldsByPage = (page: number) => {
		const invalidFieldsByPage = invalidFields.filter(
			(field: string) => allFields[field]?.page === page
		);
		let isValid = true;

		if (page === 1) {

			if (!nomeCompleto.isValid) {
				isValid = false;
				handleClick('Nome completo é obrigatório', 'error');
			}
			if (!emailCliente.isValid) {
				isValid = false;
				handleClick('E-mail é obrigatório', 'error');
			}
			if (!cpfCnpj.isValid) {
				isValid = false;
				handleClick('Documento é obrigatório', 'error');
			} else {

				if (dataOrder?.customer.documentType == 'PJ' && cpfCnpj.valor.length == 14) {
					handleClick('111', 'error');
				}
				if (dataOrder?.customer.documentType == 'PF' && cpfCnpj.valor.length == 18) {
					handleClick('222', 'error');
				}
			}
			if (!telefone.isValid) {
				isValid = false;
				handleClick('Telefone é obrigatório', 'error');
			}


		}

		if (page === 2) {

			if (!numeroCartao.isValid) {
				isValid = false;
				handleClick('Número no cartão é obrigatório', 'error');
			}
			if (!validadeCartao.isValid) {
				isValid = false;
				handleClick('Validade do cartão é obrigatório', 'error');
			}
			if (!cvvCartao.isValid) {
				isValid = false;
				handleClick('Cvv do cartão é obrigatório', 'error');
			}
			if (!nomeCartao.isValid) {
				isValid = false;
				handleClick('Nome do cartão é obrigatório', 'error');
			}
			if (!cpfCartao.isValid) {
				isValid = false;
				handleClick('Cpf do cartão é obrigatório', 'error');
			}
			if (!parcelaCartao.isValid) {
				isValid = false;
				handleClick('Parcela do cartão é obrigatório', 'error');
			}
			if (!cep.isValid) {
				isValid = false;
				handleClick('Cep é obrigatório', 'error');
			}
			if (!endereco.isValid) {
				handleClick('Endereço é obrigatório', 'error');
			}
			if (!numero.isValid) {
				isValid = false;
				handleClick('Número é obrigatório', 'error');
			}
			if (!bairro.isValid) {
				isValid = false;
				handleClick('Bairro é obrigatório', 'error');
			}
			if (!cidade.isValid) {
				isValid = false;
				handleClick('Cidade é obrigatório', 'error');
			}
			if (!estado.isValid) {
				isValid = false;
				handleClick('Estado é obrigatório', 'error');
			}


		}

		if (!isValid) {

			return false;
		}

		return isValid;
	};

	const handleNextPage = async () => {
		const isValidsFields = verifyFieldsByPage(nPagina);


		if (nPagina === 1) {
			if (isValidsFields) {
				setNpagina(2);
				setNumberStep(1);

				switch (formaDePagamento.valor) {
					case 'PIX':
						setMostraPix(true);
						setMostraCredito(false);
						setTypeFormPayment('pix');
						break;

					case 'CREDIT_CARD':
						setMostraPix(false);
						setMostraCredito(true);
						setTypeFormPayment('credito');
						break;
					case 'NONE':
						setMostraPix(true)
						setMostraCredito(true);
						setTypeFormPayment('credito');
						break;



					default:
						break;
				}

			}
		}

		if (nPagina === 2) {
			if (isValidsFields) {

				paymentOrder()
			}
		}
	};

	const handleClick = (menssage: string, type: AlertColor) => {
		setMenssageAlert(menssage);
		setTypeAlert(type)
		setOpenAlert(true);
	};

	const getCep = async (cep: any) => {

		try {

			const response: any = await getAddressByCep(cep);

			if (response.status == 200) {

				setFieldRedux('endereco', {
					name: 'endereco',
					valor: response.data.logradouro,
					isValid: true,
					page: 2,
				});
				setFieldRedux('bairro', {
					name: 'bairro',
					valor: response.data.bairro,
					isValid: true,
					page: 2,
				});
				setFieldRedux('cidade', {
					name: 'cidade',
					valor: response.data.localidade,
					isValid: true,
					page: 2,
				});
				setFieldRedux('estado', {
					name: 'estado',
					valor: response.data.uf,
					isValid: true,
					page: 2,
				});

			}

		} catch (error) {

			console.log(error);

		}
	}


	const paymentOrder = async () => {
		setIsOpen(true);

		const dataMonth = validadeCartao.valor.substring(0, 2);
		const dataYear = validadeCartao.valor.substring(3, 5);
		const jsonString = JSON.stringify(itensOrder);
		const dataPayment = {
			"amount": String(totalAmount),
			"paymentCardType": "credit",
			"installments": parcelaCartao.valor,
			"paymentOrderCode": dataOrder?.orderCode,
			"card": {
				"number": numeroCartao.valor.replace(/\D/g, ''),
				"holderName": nomeCartao.valor,
				"cvv": cvvCartao.valor,
				"expirationMonth": dataMonth,
				"expirationYear": dataYear,
			},
			"customer": {
				"name": nomeCartao.valor,
				"documentType": "PF",
				"documentNumber": cpfCartao.valor,
				"email": emailCliente.valor,
				"phoneNumber": telefone.valor.replace(/\D/g, ''),
				"cellPhoneNumber": telefone.valor.replace(/\D/g, ''),
				"address": {
					"street": endereco.valor,
					"number": numero.valor,
					"complement": complemento.valor,
					"neighborhood": bairro.valor,
					"city": cidade.valor,
					"state": estado.valor,
					"zipCode": cep.valor,
					"country": "BR"
				}
			},

			"deviceInfo": {
				"ipAddress": ipClient
			}
		}

		try {
			const response = await sendPaymentOrder(dataPayment);
			console.log('retorno pagamento', response)
			if (response?.status === 201) {
				console.log('id transacao', response?.data?.data?.gateway?.paymentAuthorization?.paymentId)
				console.log('id transacao', response?.data?.data?.authorizationCode)
				setFieldRedux('idAutorizacaoPagamento', {
					name: 'idAutorizacaoPagamento',
					valor: response?.data?.data?.authorizationCode,
					isValid: true,
					page: 2,
				});
				setFieldRedux('idConfirmacaoPagamento', {
					name: 'idConfirmacaoPagamento',
					valor: response?.data?.data?.gateway?.paymentAuthorization?.paymentId,
					isValid: true,
					page: 2,
				});

				setPaymentId(response?.data?.data?.gateway?.paymentAuthorization?.paymentId);
				setAutorizationId(response?.data?.data?.authorizationCode);
				setPaymentSucesso(true);
				setIsOpen(false);

			} else {
				//console.log('ERRO', response.status);
				setIsOpen(false);
				handleClick(`${response?.data?.message}`, 'error');
			}

		} catch (error) {
			setIsOpen(false);
		}

	}

	const searchOrder = async (code: any) => {
		setIsOpen(true);

		try {
			const response = await getOrderData(code);
			console.log('dados pedido', response)
			console.log('dados vendedor',response?.data?.data?.seller);
			if (response?.status === 200) {

				setTotalAmount(response?.data?.data?.order?.amount);
				setItensOrder(response?.data?.data?.order?.items);
				setDataSeller(response?.data?.data?.seller)

				console.log('limit aqui', response?.data?.data?.installmentLimit);
				const novasParcelas: dadosSelect[] = [];
				for (let i = 1; i <= response?.data?.data?.installmentLimit; i++) {
					console.log('contador aqui', i);
					const novaParcela: dadosSelect = {
						value: i,
						label: `Pague EM ${i} x`
					};

					novasParcelas.push(novaParcela);
				}
				setDataPaymentSelect(novasParcelas)


				if (response?.data?.data?.order?.statusText == 'PAID') {
					window.location.href = `/successPage/?successCode=success&message=1&code=${code}`;
					return
				}

				setFieldRedux('formaDePagamento', {
					name: 'formaDePagamento',
					valor: response?.data?.data?.paymentsMethod[0],
					isValid: true,
					page: 2,
				});


				setDataOrder(response?.data?.data?.order)
				if (response?.data?.data?.order?.customer.name != '') {

					setFieldRedux('nomeCompleto', {
						name: 'nomeCompleto',
						valor: response?.data?.data?.order?.customer.name,
						isValid: true,
						page: 1,
					});
				}
				if (response?.data?.data?.order?.customer.email != '') {

					if (verificaEmail(response?.data?.data?.order?.customer.email) == true) {
						setFieldRedux('emailCliente', {
							name: 'emailCliente',
							valor: response?.data?.data?.order?.customer.email,
							isValid: true,
							page: 1,
						});
					} else {
						handleClick('E-mail invalido', 'error');
					}
					setFieldRedux('emailCliente', {
						name: 'emailCliente',
						valor: response?.data?.data?.order?.customer.email,
						isValid: true,
						page: 1,
					});
				}

				// setar dados endereço aqui
				if (response?.data?.data?.order?.customer.address?.zipCode != '') {
					setFieldRedux('cep', {
						name: 'cep',
						valor: response?.data?.data?.order?.customer.address?.zipCode,
						isValid: true,
						page: 2,
					});
				}

				if (response?.data?.data?.order?.customer.address?.street != '') {
					setFieldRedux('endereco', {
						name: 'endereco',
						valor: response?.data?.data?.order?.customer.address?.street,
						isValid: true,
						page: 2,
					});
				}
				if (response?.data?.data?.order?.customer.address?.number != '') {
					setFieldRedux('numero', {
						name: 'numero',
						valor: response?.data?.data?.order?.customer.address?.number,
						isValid: true,
						page: 2,
					});
				}
				if (response?.data?.data?.order?.customer.address?.complement != '') {
					setFieldRedux('complemento', {
						name: 'complemento',
						valor: response?.data?.data?.order?.customer.address?.complement,
						isValid: true,
						page: 2,
					});
				}
				if (response?.data?.data?.order?.customer.address?.neighborhood != '') {
					setFieldRedux('bairro', {
						name: 'bairro',
						valor: response?.data?.data?.order?.customer.address?.neighborhood,
						isValid: true,
						page: 2,
					});
				}
				if (response?.data?.data?.order?.customer.address?.city != '') {
					setFieldRedux('cidade', {
						name: 'cidade',
						valor: response?.data?.data?.order?.customer.address?.city,
						isValid: true,
						page: 2,
					});
				}
				if (response?.data?.data?.order?.customer.address?.state != '') {
					setFieldRedux('estado', {
						name: 'estado',
						valor: response?.data?.data?.order?.customer.address?.state,
						isValid: true,
						page: 2,
					});
				}

				if (response?.data?.data?.order?.customer.documentNumber != '') {
					let returnDocument = false;
					if (response?.data?.data?.order?.customer.documentNumber.length == 11) {
						returnDocument = validateCpf(response?.data?.data?.order?.customer.documentNumber)
					}
					if (response?.data?.data?.order?.customer.documentNumber.length == 14) {
						returnDocument = validateCnpj(response?.data?.data?.order?.customer.documentNumber)
					}
					if (returnDocument == true) {
						setHabilitaCpfCnpjEdit(true)
						setFieldRedux('cpfCnpj', {
							name: 'cpfCnpj',
							valor: virifyCpgCnpj(response?.data?.data?.order?.customer.documentNumber),
							isValid: true,
							page: 1,
						});
					} else {

						setHabilitaCpfCnpjEdit(false)
						handleClick('Documento invalido', 'error');
					}

				}

				if (response?.data?.data?.order?.customer.cellPhoneNumber != '') {

					setFieldRedux('telefone', {
						name: 'telefone',
						valor: telefoneMask(response?.data?.data?.order?.customer.cellPhoneNumber),
						isValid: true,
						page: 1,
					});
				}
				if(dataPaymentSelect.length > 0){
					
					setFieldRedux('parcelaCartao', {
						name: 'parcelaCartao',
						valor: dataPaymentSelect[0]?.value,
						isValid: true,
						page: 2,
					});
				}

				setOetornoSearchOrder(true);
				setIsOpen(false);
			} else {
				setOetornoSearchOrder(false);
				window.location.href = '/errorPage/?erroCode=error&message=1';
			}

		} catch (error) {
			setOetornoSearchOrder(false);
			setIsOpen(false);
		}
	}

	const paymentOrderPix = async () => {

		setIsOpen(true);

		const dataPaymentOrderPix = {


			amount: totalAmount,
			"expiresIn": 300,
			"paymentOrderCode": dataOrder?.orderCode,
			"customer": {
				"name": nomeCompleto.valor,
				"birthdate": dataOrder?.customer?.birthdate,
				"documentType": dataOrder?.customer?.documentType,
				"documentNumber": cpfCnpj.valor.replace(/\D/g, ''),
				"email": emailCliente.valor,
				"phoneNumber": dataOrder?.customer?.phoneNumber,
				"cellPhoneNumber": telefone.valor.replace(/\D/g, ''),
				"address": {
					"street": dataOrder?.customer?.address?.street,
					"number": dataOrder?.customer?.address?.number,
					"complement": dataOrder?.customer?.address?.complement,
					"neighborhood": dataOrder?.customer?.address?.neighborhood,
					"city": dataOrder?.customer?.address?.city,
					"state": dataOrder?.customer?.address?.state,
					"zipCode": dataOrder?.customer?.address?.zipCode,
					"country": dataOrder?.customer?.address?.country,
				}
			},
			"items": itensOrder,

		}

		try {
			const response = await paymentWithPìx(dataPaymentOrderPix);
			if (response.status == 201) {
				setImgQrCode(response?.data?.data?.charge?.qrCodeImageUrl);
				setLinkCopiaEcola(response?.data?.data?.charge?.qrCode);
				console.log('pagando com pix', response);
				setIsOpen(false);
			} else {
				setImgQrCode('');
				setLinkCopiaEcola('');
				setIsOpen(false);
				handleClick('Erro ao gerar QRcode', 'error');
			}

			if (response.status == 401) {
				setImgQrCode('');
				setLinkCopiaEcola('');
				window.location.href = `/checkoutweb/?checkoutCode=${codOrder}`;
			}
			setStarStopPix('start')

		} catch (error) {
			handleClick('Erro ao gerar QRcode', 'error');
		}
	}

	const consultaPagamentoPix = async () => {

		try {
			const response = await statusOrder(codOrder);
			console.log('retorno pagamento', response)
			if (response?.status === 200) {

				console.log('DataPagamento', response?.data?.data)
				return response?.data?.data;


			} else {

				return response?.data?.data;
			}

		} catch (error) {
			setIsOpen(false);
			return false
		}
	}

	const handleCopy = () => {
		const elemento = document.getElementById("copiarLink");
		if (elemento) {
			const texto = elemento.innerText;
			navigator.clipboard.writeText(texto)
				.then(() => {
					handleClick('Link copiado para a área de trasferência', 'success');
				})
				.catch(err => {
					console.error('Erro ao copiar texto:', err);
				});

		}
	}


	const calculaValores = (valor: any, quantidade: number) => {
		const newValue = parseFloat(valor); // 149.90
		const validaValor = quantidade * newValue;
		console.log('Valor do itens', validaValor.toFixed(2))
		return moneyMask(String(validaValor.toFixed(2)));
	}

	useEffect(() => {

		dispatch(resetUserRegisterData());

		const searchParams = new URLSearchParams(location.search);
		const code = searchParams.get('checkoutCode');
		// console.log('CODE ORDER',code);
		setCodOrder(code);
		searchOrder(code)
		fetch('https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4')
			.then(response => response.json())
			.then(data => {
				setIpClient(data.IPv4)
			})
			.catch(error => console.log(error))

		//https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4
	}, [dispatch]);

	useEffect(() => {

	}, [location.search]);

	useEffect(() => {
		if (typeFormPayment == 'pix') {
			paymentOrderPix()
		}
	}, [typeFormPayment]);

	useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null;

		const handleInterval = async () => {

			const resultado = await consultaPagamentoPix();

			if (resultado?.statusText == 'PAID') {

				setFieldRedux('idAutorizacaoPagamento', {
					name: 'idAutorizacaoPagamento',
					valor: resultado?.orderCode,
					isValid: true,
					page: 2,
				});
				setFieldRedux('idConfirmacaoPagamento', {
					name: 'idConfirmacaoPagamento',
					valor: resultado?.checkoutCode,
					isValid: true,
					page: 2,
				});

				setPaymentSucesso(true);
				if (intervalId) {
					clearInterval(intervalId);
					intervalId = null; // Limpa a variável para indicar que o intervalo foi parado
				}
			}
		};

		if (starStopPix === 'start') {
			// Inicia um intervalo para chamar a função handleInterval a cada 10 segundos
			console.log('Consulta de pagamento PIX');
			intervalId = setInterval(handleInterval, 10000); // 10000 milissegundos = 10 segundos
		}

		// Retorna uma função de limpeza para parar o intervalo quando o componente for desmontado
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [starStopPix]);

	return (

		<div className={styles.containerCenter}>

			<Header setShowMenu={setShowMenu} showMenu={showMenu} />
			<div className={styles.cardContainer}>
				{/*
				<CreditCard/>
				*/}

				{paymentSucesso == true ? (
					<Invoice
						nome={nomeCompleto.valor != '' ? nomeCompleto.valor : ''}
						documento={cpfCnpj.valor != '' ? cpfCnpj.valor : ''}
						email={emailCliente.valor != '' ? emailCliente.valor : ''}
						total={totalAmount}
						itens={itensOrder as unknown as ItensProp[]}
						paymentId={idConfirmacaoPagamento.valor}
						authorizationCode={idAutorizacaoPagamento.valor}
						tipoPagamento={formaDePagamento.valor}
						numberCard={numeroCartao.valor}
						parcelamento={parcelaCartao.valor}
					/>
				) : (
					<>
						<div className={styles.cardData}>
							<div>
								<Box sx={{ width: '100%' }}>
									<Stepper activeStep={numberStep} alternativeLabel>
										{steps.map((label) => (
											<Step key={label} >
												<StepLabel>{label}</StepLabel>
											</Step>
										))}
									</Stepper>
								</Box>
							</div>
							<div className={styles.dataCardMiu}>

								{nPagina == 1 && (
									<Card variant="outlined">
										<Box sx={{ p: 2 }}>
											<div className={styles.formCheckout}>
												<div className={styles.containerInut}>
													{errors.nomeCompleto ? (
														<p className={styles.erroInputForm}>{errors.nomeCompleto?.message}</p>
													) : (
														<></>
													)}
													<Controller
														rules={{ required: 'Por favor, selecione uma bandeira.' }}
														control={control}
														name="nomeCompleto"
														render={({ field: { onChange, onBlur, value } }) => (
															<InputFormBitClean
																placeholder="Nome completo"
																type="text"
																//readOnly
																readOnly={!!dataOrder?.customer?.name}
																onChange={(event) => {
																	const inputValue = event.target.value;
																	let isValid = true;

																	if (inputValue == '') {
																		isValid = false;

																		const auxInvalidFields = [
																			...invalidFields,
																			'nomeCompleto',
																		];
																		setFieldRedux('invalidFields', auxInvalidFields);

																	} else {
																		const returnValidName = verificarNomeCompleto(inputValue);

																		if (returnValidName == true) {
																			isValid = true;
																		} else {
																			isValid = false;
																		}

																		const auxInvalidFields = invalidFields.filter(
																			(field: string) => field !== 'nomeCompleto'
																		);
																		setFieldRedux('invalidFields', auxInvalidFields);
																	}

																	handleValueChange({
																		name: 'nomeCompleto',
																		valor: inputValue,
																		isValid: isValid,
																		page: 1,
																	});
																}}

																value={nomeCompleto.valor}
															/>
														)}
													/>

												</div>
												<div className={styles.containerInut}>
													{errors.emailCliente ? (
														<p className={styles.erroInputForm}>{errors.emailCliente?.message}</p>
													) : (
														<></>
													)}
													<Controller
														rules={{ required: 'Por favor, selecione uma bandeira.' }}
														control={control}
														name="emailCliente"
														render={({ field: { onChange, onBlur, value } }) => (
															<InputFormBitClean
																placeholder="E-mail"
																type="email"
																readOnly={!!dataOrder?.customer?.email}
																onChange={(event) => {
																	const inputValue = event.target.value;
																	let isValid = true;

																	if (inputValue == '') {
																		isValid = false;

																		const auxInvalidFields = [
																			...invalidFields,
																			'emailCliente',
																		];
																		setFieldRedux('invalidFields', auxInvalidFields);

																	} else {
																		const returnValidEmail = verificaEmail(inputValue);

																		if (returnValidEmail == true) {
																			isValid = true;
																			handleClick('E-mail valido', 'success');
																		} else {
																			isValid = true;
																			handleClick('E-mail inválido', 'error');
																		}
																		;
																		const auxInvalidFields = invalidFields.filter(
																			(field: string) => field !== 'emailCliente'
																		);
																		setFieldRedux('invalidFields', auxInvalidFields);
																	}

																	handleValueChange({
																		name: 'emailCliente',
																		valor: inputValue,
																		isValid: isValid,
																		page: 1,
																	});
																}}

																value={emailCliente.valor}
															/>
														)}
													/>

												</div>
												<div className={styles.cardInutRow}>
													<div className={styles.containerInut}>
														{errors.cpfCnpj ? (
															<p className={styles.erroInputForm}>{errors.cpfCnpj?.message}</p>
														) : (
															<></>
														)}
														<Controller
															rules={{ required: 'Por favor, selecione uma bandeira.' }}
															control={control}
															name="cpfCnpj"
															render={({ field: { onChange, onBlur, value } }) => (
																<InputFormBitClean
																	placeholder="CPF/CNPJ"
																	type="text"
																	readOnly={habilitaCpfCnpjEdit}
																	onChange={(event) => {
																		const inputValue = virifyCpgCnpj(event.target.value);
																		let isValid = false;
																		let validaDoc = '';

																		if (inputValue) {
																			validaDoc = inputValue.replace(/\D/g, '')
																			if (validaDoc.length === 11) {
																				const returnValidacao = validateCpf(inputValue);
																				if (returnValidacao == true) {
																					if (dataOrder?.customer.documentType == 'PJ') {
																						handleClick('O documento deve ser CNPJ', 'error');
																					} else {
																						isValid = true;
																					}


																				} else {
																					handleClick('CPF invalido', 'error');
																					isValid = false;
																				}

																			}
																			if (validaDoc.length === 14) {
																				const returnValidacao = validateCnpj(inputValue);

																				if (returnValidacao == true) {
																					if (dataOrder?.customer.documentType == 'PF') {
																						handleClick('O documento deve ser CPF', 'error');
																					} else {
																						isValid = true;
																					}

																				} else {
																					handleClick('CNPJ invalido', 'error');
																					isValid = false;
																				}

																			}


																			const auxInvalidFields = [
																				...invalidFields,
																				'cpfCnpj',
																			];
																			setFieldRedux('invalidFields', auxInvalidFields);

																		} else {
																			isValid = false;
																			const auxInvalidFields = invalidFields.filter(
																				(field: string) => field !== 'cpfCnpj'
																			);
																			setFieldRedux('invalidFields', auxInvalidFields);
																		}

																		handleValueChange({
																			name: 'cpfCnpj',
																			valor: inputValue,
																			isValid: isValid,
																			page: 1,
																		});
																	}}

																	value={cpfCnpj.valor}
																/>
															)}
														/>
													</div>
													<div className={styles.containerInut}>
														{errors.telefone ? (
															<p className={styles.erroInputForm}>{errors.telefone?.message}</p>
														) : (
															<></>
														)}
														<Controller
															rules={{ required: 'Por favor, selecione uma bandeira.' }}
															control={control}
															name="telefone"
															render={({ field: { onChange, onBlur, value } }) => (
																<InputFormBitClean
																	placeholder="Celular"
																	type="text"
																	readOnly={!!dataOrder?.customer?.cellPhoneNumber}
																	onChange={(event) => {
																		if (event.target.value.length < 16) {
																			const inputValue = telefoneMask(event.target.value);
																			let isValid = true;


																			if (inputValue == '') {
																				isValid = false;

																				const auxInvalidFields = [
																					...invalidFields,
																					'telefone',
																				];
																				setFieldRedux('invalidFields', auxInvalidFields);

																			} else {
																				isValid = true;
																				const auxInvalidFields = invalidFields.filter(
																					(field: string) => field !== 'telefone'
																				);
																				setFieldRedux('invalidFields', auxInvalidFields);
																			}

																			handleValueChange({
																				name: 'telefone',
																				valor: inputValue,
																				isValid: isValid,
																				page: 1,
																			});
																		}

																	}}

																	value={telefone.valor}
																/>
															)}
														/>
													</div>


												</div>
												<Stack direction="row" spacing={2} alignItems="center" justifyContent="space-around" paddingTop={2}>
													<Button variant="contained" color="warning">Voltar</Button>
													<Button variant="contained" color="success" onClick={handleNextPage}>
														Continuar
													</Button>

												</Stack>
											</div>
										</Box>
									</Card>
								)}

								{/* FORMULÁRIO DADOS PAGAMENTO*/}

								{nPagina == 2 && (

									<div>
										<div className={styles.cardformPayment}>
											<div className={styles.contentTitleRow}>
												<h2>Parcelamento</h2>
											</div>
											<div className={styles.contantIconPayment}>
												<div className={styles.cardIcon}>
													{mostraCredito == true && (
														<div>
															<Button
																variant={typeFormPayment == 'credito' ? "contained" : "outlined"}
																color="success"
																startIcon={<CartaoIconCheckout fill={typeFormPayment == 'credito' ? "#FFF" : undefined} />}
																sx={{
																	height: '60px', // Set desired height
																	width: '150px', // Set desired width
																}}
																onClick={() => { setTypeFormPayment('credito') }}
															>
																<span className={styles.textButtonPayment}>Cartao de crédito</span>
															</Button>
														</div>
													)}
													{mostraPix == true && (
														<div>
															<Button
																variant={typeFormPayment == 'pix' ? "contained" : "outlined"}
																color="success"
																startIcon={<PixIconCheckout fill={typeFormPayment == 'pix' ? "#FFF" : undefined} />}
																sx={{
																	height: '60px', // Set desired height
																	width: '150px', // Set desired width
																}}
																onClick={() => { setTypeFormPayment('pix') }}
															>
																<span className={styles.textButtonPayment}>Pagamento via pix</span>
															</Button>
														</div>
													)}
													{/*
													<div>
														<Button
															variant={typeFormPayment == 'boleto' ? "contained" : "outlined"}
															color="success"
															startIcon={<PagamentoIconCheckout fill={typeFormPayment == 'boleto' ? "#FFF" : undefined} />}
															sx={{
																height: '60px', // Set desired height
																width: '150px', // Set desired width
															}}
															onClick={() => { setTypeFormPayment('boleto'); }}
														>
															<span className={styles.textButtonPayment}>Pagamento via boleto</span>
														</Button>
													</div>
													
														*/}


												</div>
											</div>
										</div>
										{typeFormPayment == 'credito' && (
											<div className={styles.dataCardMiu}>
												<Card variant="outlined">
													<Box sx={{ p: 2 }}>
														<div className={styles.formCheckout}>
															<div className={styles.contentTitleRow}>
																<h3>Dados do cartão</h3>
															</div>
															<div className={styles.cardInutRow}>
																<div className={styles.containerInut}>
																	{errors.numeroCartao ? (
																		<p className={styles.erroInputForm}>{errors.numeroCartao?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="numeroCartao"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Número do cartão"
																				type="text"
																				onChange={(event) => {
																					handleInputFocus('number');
																					const inputValue = numberCardCreditMask(event.target.value);
																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'numeroCartao',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						const returnNumber = validNumberCreditCard(inputValue);

																						if (returnNumber == true) {
																							isValid = true;
																						} else {
																							isValid = false;
																						}

																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'numeroCartao'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'numeroCartao',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={numeroCartao.valor}
																			/>
																		)}
																	/>

																</div>
																<div className={styles.containerInut}>
																	{errors.validadeCartao ? (
																		<p className={styles.erroInputForm}>{errors.validadeCartao?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="validadeCartao"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Validade do cartão"
																				type="text"
																				onChange={(event) => {
																					handleInputFocus('expiry');
																					const inputValue = event.target.value.length <= 5 ? validateCreditCard(event.target.value) : '';
																					const validaMes = inputValue.slice(0, 2);
																					const validaAno = inputValue.slice(3, 5);

																					let isValid = true;

																					if (inputValue == '' || inputValue.length != 5) {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'validadeCartao',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						if (parseInt(validaMes) > 12) {
																							isValid = false
																							handleClick('Mês invalido', 'error');
																						} else {
																							isValid = true;
																						}

																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'validadeCartao'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'validadeCartao',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});


																				}}

																				value={validadeCartao.valor}
																			/>
																		)}
																	/>

																</div>
																<div className={styles.containerInutCvv}>
																	{errors.cvvCartao ? (
																		<p className={styles.erroInputForm}>{errors.cvvCartao?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="cvvCartao"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Cvv"
																				type="text"
																				//onFocus={handleInputFocus('expiry')}
																				onChange={(event) => {
																					handleInputFocus('cvc');

																					const inputValue = event.target.value;
																					if (inputValue.length < 4) {
																						let isValid = true;

																						if (inputValue == '') {
																							isValid = false;

																							const auxInvalidFields = [
																								...invalidFields,
																								'cvvCartao',
																							];
																							setFieldRedux('invalidFields', auxInvalidFields);

																						} else {
																							if (inputValue.length == 3) {
																								isValid = true;
																							}

																							const auxInvalidFields = invalidFields.filter(
																								(field: string) => field !== 'cvvCartao'
																							);
																							setFieldRedux('invalidFields', auxInvalidFields);
																						}

																						handleValueChange({
																							name: 'cvvCartao',
																							valor: inputValue,
																							isValid: isValid,
																							page: 2,
																						});
																					}

																				}}

																				value={cvvCartao.valor}
																			/>
																		)}
																	/>

																</div>
															</div>
															<div className={styles.cardInutRow}>
																<div className={styles.containerInut}>
																	{errors.nomeCartao ? (
																		<p className={styles.erroInputForm}>{errors.nomeCartao?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="nomeCartao"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Nome (igual no cartão)"
																				type="text"
																				onChange={(event) => {
																					handleInputFocus('name');
																					const inputValue = event.target.value;

																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'nomeCartao',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						const returnValidName = verificarNomeCompleto(inputValue);

																						if (returnValidName == true) {
																							isValid = true;
																						} else {
																							isValid = false;
																						}
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'nomeCartao'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'nomeCartao',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={nomeCartao.valor}
																			/>
																		)}
																	/>

																</div>
																<div className={styles.containerInut}>
																	{errors.cpfCartao ? (
																		<p className={styles.erroInputForm}>{errors.cpfCartao?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="cpfCartao"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="CPF do titular"
																				type="text"
																				onChange={(event) => {
																					console.log('dados da pacela', selecParcelaPagamento)
																					const inputValue = virifyCpgCnpj(event.target.value);
																					let isValid = false;
																					let validaDoc = '';

																					if (inputValue) {
																						validaDoc = inputValue.replace(/\D/g, '')
																						if (validaDoc.length === 11) {
																							const returnValidacao = validateCpf(inputValue);

																							if (returnValidacao == true) {
																								isValid = true;

																							} else {
																								handleClick('CPF invalido', 'error');
																								isValid = false;
																							}

																						}
																						if (validaDoc.length === 14) {
																							const returnValidacao = validateCnpj(inputValue);

																							if (returnValidacao == true) {
																								isValid = true;

																							} else {
																								handleClick('CNPJ invalido', 'error');
																								isValid = false;
																							}

																						}


																						const auxInvalidFields = [
																							...invalidFields,
																							'cpfCartao',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = false;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'cpfCartao'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'cpfCartao',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={cpfCartao.valor}
																			/>
																		)}
																	/>
																</div>

															</div>

															<div className={styles.contentCardCredit}>
																<div className={styles.habilitaCardCredit}>
																	<Cards
																		number={numeroCartao.valor}
																		name={nomeCartao.valor}
																		expiry={validadeCartao.valor}
																		cvc={cvvCartao.valor}
																		focused={focus}
																	/>
																</div>
																<div className={styles.componentCardCredit}>
																	<h3>Parcelamento</h3>
																	<Controller
																		control={control}
																		name="parcelaCartao"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<Select
																				placeholder="Selecione a Filial"
																				options={dataPaymentSelect}
																				className="basic-single"
																				value={dataPaymentSelect.find((c) => c.value === Number(value))}
																				onChange={(val: any) => {
																					console.log('dados do select', selecParcelaPagamento)
																					onChange(val.value)
																					const inputValue = val.value;
																					let isValid = false;
																					if (inputValue > 0) {
																						isValid = true;
																						const auxInvalidFields = [
																							...invalidFields,
																							'parcelaCartao',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);
																					} else {
																						isValid = false;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'parcelaCartao'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}
																					handleValueChange({
																						name: 'parcelaCartao',
																						valor: inputValue,
																						isValid: isValid,
																						page: 1,
																					});
																				}}
																				defaultValue={dataPaymentSelect.length > 0 ? { value: dataPaymentSelect[0]?.value, label: dataPaymentSelect[0]?.label } : null} // Define a primeira opção como padrão
																			/>
																		)}
																	/>

																</div>

															</div>

															<div className={styles.contentTitleRow}>
																<h3>Endereço de cobrança</h3>
															</div>

															<div className={styles.cardInutRow}>
																<div className={styles.containerInut}>

																	<Controller
																		control={control}
																		name="cep"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="CEP"
																				type="text"
																				readOnly={!!dataOrder?.customer?.address.zipCode}
																				onChange={async (event) => {
																					const inputValue = cepMask(event.target.value);
																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'cep',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = true;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'cep'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'cep',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});

																					if (inputValue.length == 9) {
																						getCep(inputValue.replace(/\D/g, ''));


																					}
																				}}

																				value={cep.valor}
																			/>
																		)}
																	/>
																</div>
																<div className={styles.containerInut}>

																	<Controller
																		control={control}
																		name="endereco"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Endereço"
																				type="text"
																				readOnly={!!dataOrder?.customer?.address.neighborhood}
																				onChange={async (event) => {
																					const inputValue = event.target.value;
																					let isValid = true;
																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'endereco',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = true;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'endereco'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'endereco',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});


																				}}

																				value={endereco.valor}
																			/>
																		)}
																	/>
																</div>
															</div>
															<div className={styles.cardInutRow}>
																<div className={styles.containerInut}>
																	{errors.numero ? (
																		<p className={styles.erroInputForm}>{errors.numero?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="numero"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Número"
																				type="text"
																				readOnly={!!dataOrder?.customer?.address.number}
																				onChange={(event) => {
																					const inputValue = event.target.value;
																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'numero',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = true;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'numero'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'numero',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={numero.valor}
																			/>
																		)}
																	/>

																</div>
																<div className={styles.containerInut}>
																	{errors.complemento ? (
																		<p className={styles.erroInputForm}>{errors.complemento?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="complemento"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Complemento (Opcional)"
																				type="text"
																				readOnly={!!dataOrder?.customer?.address.complement}
																				onChange={(event) => {
																					const inputValue = event.target.value;
																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'complemento',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = true;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'complemento'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'complemento',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={complemento.valor}
																			/>
																		)}
																	/>
																</div>

															</div>
															<div className={styles.containerInutCvv}>

																<Controller
																	control={control}
																	name="bairro"
																	render={({ field: { onChange, onBlur, value } }) => (
																		<InputFormBitClean
																			placeholder="Bairro"
																			type="text"
																			readOnly={!!dataOrder?.customer?.address.neighborhood}
																			onChange={async (event) => {
																				const inputValue = event.target.value;
																				let isValid = true;

																				if (inputValue == '') {
																					isValid = false;

																					const auxInvalidFields = [
																						...invalidFields,
																						'bairro',
																					];
																					setFieldRedux('invalidFields', auxInvalidFields);

																				} else {
																					isValid = true;
																					const auxInvalidFields = invalidFields.filter(
																						(field: string) => field !== 'bairro'
																					);
																					setFieldRedux('invalidFields', auxInvalidFields);
																				}

																				handleValueChange({
																					name: 'bairro',
																					valor: inputValue,
																					isValid: isValid,
																					page: 2,
																				});


																			}}

																			value={bairro.valor}
																		/>
																	)}
																/>
															</div>
															<div className={styles.cardInutRow}>
																<div className={styles.containerInut}>
																	{errors.cidade ? (
																		<p className={styles.erroInputForm}>{errors.cidade?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="cidade"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Cidade"
																				type="text"
																				readOnly={!!dataOrder?.customer?.address.city}
																				onChange={(event) => {
																					const inputValue = event.target.value;
																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'cidade',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = true;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'cidade'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'cidade',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={cidade.valor}
																			/>
																		)}
																	/>

																</div>
																<div className={styles.containerInut}>
																	{errors.estado ? (
																		<p className={styles.erroInputForm}>{errors.estado?.message}</p>
																	) : (
																		<></>
																	)}
																	<Controller
																		rules={{ required: 'Por favor, selecione uma bandeira.' }}
																		control={control}
																		name="estado"
																		render={({ field: { onChange, onBlur, value } }) => (
																			<InputFormBitClean
																				placeholder="Estado"
																				type="text"
																				readOnly={!!dataOrder?.customer?.address.state}
																				onChange={(event) => {
																					const inputValue = event.target.value;
																					let isValid = true;

																					if (inputValue == '') {
																						isValid = false;

																						const auxInvalidFields = [
																							...invalidFields,
																							'estado',
																						];
																						setFieldRedux('invalidFields', auxInvalidFields);

																					} else {
																						isValid = true;
																						const auxInvalidFields = invalidFields.filter(
																							(field: string) => field !== 'estado'
																						);
																						setFieldRedux('invalidFields', auxInvalidFields);
																					}

																					handleValueChange({
																						name: 'estado',
																						valor: inputValue,
																						isValid: isValid,
																						page: 2,
																					});
																				}}

																				value={estado.valor}
																			/>
																		)}
																	/>
																</div>

															</div>
															<Stack direction="row" spacing={2} alignItems="center" justifyContent="space-around" paddingTop={2}>
																<Button variant="contained" color="warning" onClick={() => { setNpagina(1), setNumberStep(0) }}>Voltar</Button>
																<Button variant="contained" color="success" onClick={handleNextPage}>
																	Continuar
																</Button>

															</Stack>
														</div>
													</Box>
												</Card>
											</div>
										)}
										{typeFormPayment == 'pix' && (
											<div style={{ padding: 32 }}>
												<Card variant="outlined">
													<Box sx={{ p: 2, }}>
														<Stack direction="column" justifyContent="center" alignItems="center">
															<Typography gutterBottom variant="h5" component="div">
																Leia o QrCode
															</Typography>
															<Typography gutterBottom variant="h6" component="div">
																<img src={imgQrCode} />
															</Typography>
														</Stack>
														<Stack direction="column" justifyContent="center" alignItems="center">
															<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }} >
																Copia e cola:
															</Typography>
															<Typography id='copiarLink' gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }} justifyContent="center" alignItems="center">
																{linkCopiaEcola}
															</Typography>
														</Stack>
														<Stack direction="column" justifyContent="center" alignItems="center">
															<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }} >
																<Button variant="contained" color="success" onClick={() => { handleCopy() }}>
																	Copia e cola
																</Button>
															</Typography>
														</Stack>
													</Box>
												</Card>
											</div>
										)}
									</div>

								)}

							</div>


						</div>
						<div className={styles.cardDetails}>
							<Card variant="outlined">
								<Box sx={{ p: 2 }}>
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<Typography gutterBottom variant="h5" component="div">
											Vendido por
										</Typography>
										<Typography gutterBottom variant="h6" component="div">
											{dataSeller?.companyName}
										</Typography>
									</Stack>
									<Stack direction="column">
										<Typography gutterBottom variant="h6" component="div">
											Resumo da compra
										</Typography>
										<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }}>
											Produtos
										</Typography>
									</Stack>

								</Box>
								{itensOrder.map((item) => (
									<>
										<Box sx={{ p: 2 }} key={item.productCode}>
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												<Typography gutterBottom variant="h5" component="div">
													<img src={ImgCarrinho} style={{ width: '70px', height: '70px' }} />
												</Typography>
												<Stack direction="column" justifyContent="center" alignItems="center">
													<Typography gutterBottom variant="h5" component="div" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
														{item.productName}
													</Typography>
													<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }}>
														{item.quantity} {item.quantity < 2 ? `Unidade` : 'Unidades'}
													</Typography>
													<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }}>
														Valor unitário
													</Typography>
													<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }}>
														R$ {moneyMask(String(item.unitPrice))}
													</Typography>
												</Stack>
												<Stack direction="column" justifyContent="center" alignItems="center">
													<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }}>
														Total
													</Typography>
													<Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '16px' }}>
														R$ {calculaValores(item.unitPrice, item.quantity)}

													</Typography>
												</Stack>

											</Stack>

										</Box>
										<Divider />
									</>

								))}

								<Box sx={{ p: 2 }}>
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<Typography gutterBottom variant="h5" component="div">
											Sub total
										</Typography>
										<Typography gutterBottom variant="h6" component="div">
											{moneyMask(String(totalAmount))}
										</Typography>
									</Stack>
								</Box>
								<Divider />
								<Box sx={{ p: 2 }}>
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										<Typography gutterBottom variant="h5" component="div">
											Total a pagar
										</Typography>
										<Typography gutterBottom variant="h6" component="div">
											{moneyMask(String(totalAmount))}

										</Typography>
									</Stack>
								</Box>
							</Card>


						</div>
					</>
				)}

			</div>
			{
				openAlert && (
					<AlertSnack
						message={menssageAlert}
						typeMessage={typeAlert}
						openAlertComponent={openAlert}
						onBack={() => { setOpenAlert(!openAlert) }}
					/>
				)
			}
			{modalIsOpen ? <Spinner /> : ''}
		</div>


	);
}

const mapStateToProps = (state: any) => {
	return {
		allFields: getPropSegura(state, ['userRegisterReducer'], {}),
		invalidFields: getPropSegura(
			state,
			['userRegisterReducer', 'invalidFields'],
			[]
		),

		nomeCompleto: getPropSegura(
			state,
			['userRegisterReducer', 'nomeCompleto'],
			{
				valor: '',
				isValid: false,
				page: 1,
			}
		),
		emailCliente: getPropSegura(
			state,
			['userRegisterReducer', 'emailCliente'],
			{
				valor: '',
				isValid: false,
				page: 1,
			}
		),
		cpfCnpj: getPropSegura(
			state,
			['userRegisterReducer', 'cpfCnpj'],
			{
				valor: '',
				isValid: false,
				page: 1,
			}
		),
		telefone: getPropSegura(
			state,
			['userRegisterReducer', 'telefone'],
			{
				valor: '',
				isValid: false,
				page: 1,
			}
		),

		numeroCartao: getPropSegura(
			state,
			['userRegisterReducer', 'numeroCartao'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		validadeCartao: getPropSegura(
			state,
			['userRegisterReducer', 'validadeCartao'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		cvvCartao: getPropSegura(
			state,
			['userRegisterReducer', 'cvvCartao'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		nomeCartao: getPropSegura(
			state,
			['userRegisterReducer', 'nomeCartao'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		cpfCartao: getPropSegura(
			state,
			['userRegisterReducer', 'cpfCartao'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		parcelaCartao: getPropSegura(
			state,
			['userRegisterReducer', 'parcelaCartao'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		cep: getPropSegura(
			state,
			['userRegisterReducer', 'cep'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		endereco: getPropSegura(
			state,
			['userRegisterReducer', 'endereco'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		numero: getPropSegura(
			state,
			['userRegisterReducer', 'numero'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		complemento: getPropSegura(
			state,
			['userRegisterReducer', 'complemento'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		bairro: getPropSegura(
			state,
			['userRegisterReducer', 'bairro'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		cidade: getPropSegura(
			state,
			['userRegisterReducer', 'cidade'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		estado: getPropSegura(
			state,
			['userRegisterReducer', 'estado'],
			{
				valor: '',
				isValid: false,
				page: 2,
			}
		),

		idAutorizacaoPagamento: getPropSegura(
			state,
			['userRegisterReducer', 'idAutorizacaoPagamento'],
			{
				valor: '',
				isValid: true,
				page: 2,
			}
		),
		idConfirmacaoPagamento: getPropSegura(
			state,
			['userRegisterReducer', 'idConfirmacaoPagamento'],
			{
				valor: '',
				isValid: true,
				page: 2,
			}
		),
		formaDePagamento: getPropSegura(
			state,
			['userRegisterReducer', 'formaDePagamento'],
			{
				valor: '',
				isValid: true,
				page: 2,
			}
		),



	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		setFieldRedux: (field: string, value: string) => {
			dispatch(setUserRegisterField(field, value));
		},
		resetUserRegisterDataRedux: () => {
			dispatch(resetUserRegisterData());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);