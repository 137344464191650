import { Routes, Route, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Checkout from '../pages/checkoutweb';
import ErrorPage from '../pages/errorPage';
import SuccessPage from '../pages/successPage';

export function Rotas() {
	return (
		<Routes>
			<Route path="/checkoutweb" element={<Checkout />} />
			<Route path="/errorPage" element={<ErrorPage />} />
			<Route path="/successPage" element={<SuccessPage />} />
			
		</Routes>
	);
}
