

export const cpfMask = (value: string) => {
	if (value) {
		return value
			.replace(/\D/g, '')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d{1,2})/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1')
			.replace(/(\d{2})' '\d+?$/, '$1');
	} else {
		return '';
	}
};

export const cnpjMask = (value: string) => {
	if (value) {
		return value
			.replace(/\D/g, '')
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{4})\d+?$/, '$1');
		//.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
	} else {
		return '';
	}
};

export const moneyMask = (value: string) => {
	console.log('mascara', value)
	if (value) {
		//if(typeof value === "undefined" || value == '')
		// return 0;

		let valor = '';

		if (typeof value !== 'undefined') {
			valor = value;
		} else {
			valor = value;
		}

		if (valor.length == 2) {
			return valor;
		}
		let v = valor;
		v = v.replace(/\D/g, '');
		v = v.replace(/(\d{1,2})$/, ',$1');
		v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
	
		return v;
	} else {
		return '';
	}
};

export const formaTavalorCelcoin = (value: string) => {
	if (value) {
		return value.replace(/[^0-9,]*/g, '').replace(',', '.');
		//.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
	} else {
		return '';
	}
};

export const formaTavalorCelcoinInverso = (value: string) => {
	if (value) {
		return value.replace(/[^0-9,]*/g, '').replace('.', ',');
		//.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
	} else {
		return '';
	}
};

export function formatarCPFeCNPJ(valor: string) {
	if (!valor) return valor;
	const documento = valor.replace(/\D/g, '');
	if (documento.length === 11) {
		return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	} else if (documento.length === 14) {
		return documento.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			'$1.$2.$3/$4-$5'
		);
	} else {
		return valor;
	}
}

export function formatarCPF(valor: string) {
	if (!valor) return valor;
	const documento = valor.replace(/\D/g, '');
	
		return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	
}

export function formatarTelefone(valor: string) {
	if (!valor) return valor;
	const documento = valor.replace(/\D/g, '');
	
	return documento.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
}

export function formatarCep(valor: string) {
	if (!valor) return valor;
	const documento = valor.replace(/\D/g, '');
	
	return documento.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
}

export function birthDateMask (text: string) {
	if (!text) return text;
	if (text.length === 0) return text;
	let x: any = text.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
	if (text.length <= 10) {
		text = !x[2]
			? x[1]
			: x[1] + (x[2] ? '/' + x[2] : '') + (x[3] ? '/' + x[3] : '');
		return text;
	}
	return text.slice(0, 10);
};

export function telefoneMask(text: string) {
	console.log('texto aqui',text)
	if (!text) return text;
	if (text.length === 0) return text;
	let x: any = text.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
	if (text.length < 16) {
		text = !x[2]
			? x[1]
			: '(' + x[1] + (x[2] ? ') ' + x[2] : '') + (x[3] ? '-' + x[3] : '');
			console.log(text)
		return text;
	}
	return text.slice(0, 15);
};


export function validaCpf(text: string) {
	
if (!text) return text;
if (text.length === 0) return text;
let x: any = text
	.replace(/\D/g, '')
	.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
if (text.length <= 14) {
	text = !x[2]
		? x[1]
		: x[1] +
		  (x[2] ? '.' + x[2] : '') +
		  (x[3] ? '.' + x[3] : '') +
		  (x[4] ? '-' + x[4] : '');
	return text;
}
return text.slice(0, 14);


};

export function validaCnpj(text: string) {
	
	if (!text) return text;
	if (text.length === 0) return text;
	let x: any = text
		.replace(/\D/g, '')
		.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
	if (text.length <= 18) {
		text = !x[2]
			? x[1]
			: x[1] +
			  (x[2] ? '.' + x[2] : '') +
			  (x[3] ? '.' + x[3] : '') +
			  (x[4] ? '/' + x[4] : '') +
			  (x[5] ? '.' + x[5] : '');
		return text;
	}
	return text.slice(0, 17);
	
	
	};

