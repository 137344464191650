import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: 'var(--BACKGOUND_SPINNER_900)',
	},
	text: {
		marginTop: theme.spacing(1),
		position: 'absolute',
		top: '49%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: 'white',
		fontSize: '10px', // Alterar o tamanho do texto
	  },
}));

export function Spinner() {
	const classes = useStyles();
	return (
		<div>
			<Backdrop className={classes.backdrop} open>
				<CircularProgress style={{ color: '#FF9900' }} size={60}/>
				<Typography variant="body1" className={classes.text}>
          Aguarde...
        </Typography>
			</Backdrop>
		</div>
	);
}
