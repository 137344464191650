import React, { useState, useEffect, useCallback } from 'react';


import { useSelector, useDispatch } from 'react-redux';

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useMediaQuery } from 'usehooks-ts';



import { setSaldoData } from 'redux/actions/saldoActions';



import getImg from '../../assets/globalPay/logo.png';

import ImgAvatar from 'assets/geral/pngwing.com.png';

import styles from './styles.module.scss';

export function Header({
	setShowMenu,
	showMenu,
}: {
	setShowMenu: any;
	showMenu: boolean;
}) {
	const [habilitaSaldo, setHabilitaSaldo] = useState(true);
	//const dispatch = useDispatch();

	const user = useSelector((state: any) => state.userReducer.user);
	const saldo = useSelector((state: any) => state.saldoReducer.saldo);

	const isMobile = useMediaQuery('(max-width: 600px)');

	function chengeSaldo() {
		setHabilitaSaldo((prev) => (!prev ? true : false));
	}





	return (
		<div className={styles.containerHeader}>
			<div className={styles.containerEsquerdo}>
				<div className={styles.containerLogo}>
					<img
						className={styles.divImgLogo}
						src={getImg}
						alt="logo"
					/>

				</div>

			</div>

			<div className={styles.containerDireito}>


				<div className={styles.containerMenuAcoes}>

				</div>
				{/*
					<div className={styles.containerchat}>
						<Link className={styles.containerIcon} href="/">
							<MdChat size={23} />
						</Link>
					</div>

					<div className={styles.containerchat}>
						<Link className={styles.containerIcon} href="/configuracoes">
							<MdOutlineSettings size={23} />
						</Link>
					</div>
				*/}
			</div>
		</div>
	);
}
