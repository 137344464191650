import { combineReducers } from 'redux';

import userReducer from './userReducer';
import saldoReducer from './saldoReducer';
import userRegisterReducer from './userRegisterReducer';
import userFormReducer from './userFormReducer';

const rootReducer = combineReducers({
	userReducer,
	saldoReducer,
	userRegisterReducer,
	userFormReducer,
});

export default rootReducer;
