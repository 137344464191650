import styles from './styles.module.scss';

import InputMask from 'react-input-mask';

import Select from 'react-select';

import {
	InputHTMLAttributes,
	SelectHTMLAttributes,
	TextareaHTMLAttributes,
} from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {}
interface MaskInputProps {
	mask: string;
	placeholder?: string;
	value?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
  }

export function InputFormBit({ ...rest }: InputProps) {
	return <input className={styles.inputComponente} {...rest} />;
}

export function InputFormBitClean({ ...rest }: InputProps) {
	return <input className={styles.inputComponenteClean} {...rest} />;
}

export function TextArea({ ...rest }: TextAreaProps) {
	return <TextArea className={styles.inputComponente} {...rest}></TextArea>;
}

export function SelectInputBit({ ...rest }: SelectProps) {
	return <select className={styles.inputComponenteSelect} {...rest} />;
}

export function MaskInput(props: any) {
	return (
		<InputMask
			className={styles.inputComponente}
			mask={props.mask}
			placeholder={props.placeholder}
			type="text"
			onChange={props.onChange}
			onBlur={props.onBlur}
			value={props.value}
		/>
	);
}
