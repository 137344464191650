import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Paper, Grid, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/system';
import { GoCheckCircleFill } from "react-icons/go";
import { PaymentIcon } from 'react-native-payment-icons'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { geraPdfComprovante } from '../../utils/comprovantePdf';
import { moneyMask } from '../../utils/cpfMask'
import { Header } from '../../layout/Header';
import { useMediaQuery } from 'usehooks-ts';
interface ItensProp {
  productCode: string;
  productName: string;
  productSKU: string;
  quantity: string;
  unitPrice: number;

}
type dataInvoiceProps = {
  nome: string;
  documento: string;
  email: string;
  itens?: ItensProp[]
  total: number;
}
// Estilo para o Paper que representa a fatura
const InvoicePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
}));
export default function successPage() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [showMenu, setShowMenu] = useState(isMobile ? false : true);
  const divRef = useRef(null);
  const [message, setMessage] = useState('');
  const [typeErro, setTypeErro] = useState('');
  const [codeOrder, setCodeOrder] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const message = searchParams.get('message');


    if (code) {
      setCodeOrder(code);
    }
    if (message) {
      setMessage(message)
    }


    console.log(code)
    console.log(message)
  }, [])
  return (
    <Container maxWidth="lg">
      <Header setShowMenu={setShowMenu} showMenu={showMenu} />
      <Grid container justifyContent="center" paddingTop={16} >
        <Grid item xs={6} container justifyContent="center" alignItems="center">
          {typeErro == 'error' ? (
            <>

              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="outlined" severity="error">
                  {message == '1' ? 'Ordem não encontrada' : ''}
                </Alert>
              </Stack>
            </>

          ) : (
            <>
              <GoCheckCircleFill size={64} color='#0d766e' />
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="outlined" severity="success">
                  {message == '1' && (
                    <>
                      <div>
                        Já existe um pagamento para a ordem
                      </div>
                      <div>
                        {codeOrder}
                      </div>
                    </>

                  )}
                </Alert>
              </Stack>
            </>
          )}

        </Grid>
      </Grid>
    </Container>
  );
};