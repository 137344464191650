
export function validNumberCreditCard(numero: string) {
	 // Remove qualquer espaço em branco
	 numero = numero.replace(/\s/g, '');

	 // Verifica se o número consiste apenas em dígitos e possui entre 12 e 19 caracteres
	 if (!/^\d{12,19}$/.test(numero)) {
		 return false;
	 }
 
	 // Verifica se o número passa no algoritmo de Luhn
	 let sum = 0;
	 let double = false;
	 for (let i = numero.length - 1; i >= 0; i--) {
		 let digit = parseInt(numero.charAt(i), 10);
		 if (double) {
			 digit *= 2;
			 if (digit > 9) {
				 digit -= 9;
			 }
		 }
		 sum += digit;
		 double = !double;
	 }
	 return sum % 10 === 0;
}

export const blurNumber = (str: string, start: number, end: number) => {
	
	// Verifica se os parâmetros são válidos
	if (typeof str !== 'string' || typeof start !== 'number' || typeof end !== 'number') {
	   return 'Não encontrado';
   }

   // Verifica se os índices de início e fim estão dentro dos limites da string
   if (start < 0 || start >= str.length || end < start || end > str.length) {
	   return 'Não encontrado';
   }

   // Gera a parte da string que será mascarada com 'X'
   const maskedPart = 'X'.repeat(end - start);

   // Concatena a string mascarada com a parte restante da string original
   const maskedString = str.substring(0, start) + maskedPart + str.substring(end);

   return maskedString;
   
 };
 
