import React, { useRef } from 'react';
import { Container, Typography, Paper, Grid, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/system';
import { GoCheckCircleFill } from "react-icons/go";
import { PaymentIcon } from 'react-native-payment-icons'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { geraPdfComprovante } from '../../utils/comprovantePdf';
import { moneyMask } from '../../utils/cpfMask'

interface ItensProp {
  productCode: string;
  productName: string;
  productSKU: string;
  quantity: string;
  unitPrice: number;

}
type dataInvoiceProps = {
  nome: string;
  documento: string;
  email: string;
  itens?: ItensProp[];
  total: number;
  paymentId: string;
  authorizationCode: string;
  tipoPagamento: string;
  numberCard?: string;
  parcelamento?: string;

}
// Estilo para o Paper que representa a fatura
const InvoicePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
}));
export default function Invoice({ nome, documento, email, itens, total, paymentId, authorizationCode, tipoPagamento, numberCard, parcelamento }: dataInvoiceProps) {
  const arrayItens = itens == undefined ? [] : itens;
  const divRef = useRef(null);

  console.log('recebendo id', paymentId)
  console.log('recebendo id', authorizationCode)

  const calculaValorUnitarioInvoice = (valor: any, quantidade: any) => {
    const newValue = parseFloat(valor); // 149.90
    const validaValor = quantidade * newValue;
    console.log('Valor do itens', validaValor.toFixed(2))
    return moneyMask(String(validaValor.toFixed(2)));
  }
  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" paddingBottom={4}>
        <Grid item xs={6} container justifyContent="center" alignItems="center">
          <GoCheckCircleFill size={64} color='#0d766e' />
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="outlined" severity="success">
              Pagamento efetuado com sucesso!!
            </Alert>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>Comprovante</Typography>
      <div ref={divRef} id="comprovante" style={{ margin: 16}}>
        <InvoicePaper>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Cliente: {nome}</Typography>
              <Typography variant="body2">{documento.length == 14 ? 'CPF' : 'CNPJ'}: {documento}</Typography>
              <Typography variant="body2">E-mail: {email}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">Id pagamento: {paymentId}</Typography>
              <Typography variant="body2">Id autorização: {authorizationCode}</Typography>
              {tipoPagamento == 'PIX' && (
                <Typography variant="body2">Forma de pagamento: Pix</Typography>
              )}
              {tipoPagamento == 'CREDIT_CARD' && (
                <>
                  <Typography variant="body2">Forma de pagamento: Cartão Nº {numberCard} </Typography>
                  <Typography variant="body2">Parcelamento {parcelamento} </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </InvoicePaper>
        <div  style={{ padding: 32}}>
           <TableContainer component={Paper}>
           <Table>
             <TableHead>
               <TableRow>
                 <TableCell>Descrição</TableCell>
                 <TableCell align="right">Quantidade</TableCell>
                 <TableCell align="right">Preço Unitário</TableCell>
                 <TableCell align="right">Total</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {arrayItens.map((item) => (
 
 
                 <TableRow key={item.productCode}>
                   <TableCell>{item.productName}</TableCell>
                   <TableCell align="right">{item.quantity} {parseInt(item.quantity) < 2 ? 'Unidade' : 'Unidades'}</TableCell>
                   <TableCell align="right">R$ {moneyMask(String(item.unitPrice))}</TableCell>
                   <TableCell align="right">R$ {calculaValorUnitarioInvoice(item.unitPrice, item.quantity)}</TableCell>
                 </TableRow>
 
               ))}
 
             </TableBody>
           </Table>
         </TableContainer>
         <Grid container justifyContent="flex-end" mt={3}>
           <Grid item>
             <Typography variant="h6">Total: R$ {moneyMask(String(total))}</Typography>
           </Grid>
         </Grid>
        </div>
       
      </div>
      <Button variant="contained" color="success" onClick={() => { geraPdfComprovante(divRef, `${nome}`) }}>
        Imprimir
      </Button>
    </Container >
  );
};