export function validateCpf(cpf: string) {

	if (typeof cpf !== 'string') {
		return false;
	}
	let aux = cpf.split('.').join('');
	// @ts-ignore
	aux = aux.split('-');
	const digits = aux[0];
	const verifier = aux[1];
	if (verifier === undefined || verifier.length !== 2) {
		return false;
	}

	let sum = 0;
	for (let i = 0; i <= 8; i += 1) {
		sum += Number(digits[i]) * (10 - i);
	}

	let remainder = sum % 11;
	if (remainder < 2) {
		remainder = 0;
	} else {
		remainder = 11 - remainder;
	}

	const verifierDict = {
		0: 0,
		1: 1,
		2: 2,
		3: 3,
		4: 4,
		5: 5,
		6: 6,
		7: 7,
		8: 8,
		9: 9,
		X: 10,
		x: 10,
	};
	//@ts-ignore
	const verifier0 = verifierDict[verifier[0]];
	if (verifier0 === undefined) {
		return false;
	}
	//@ts-ignore
	const verifier1 = verifierDict[verifier[1]];
	if (verifier1 === undefined) {
		return false;
	}
	if (remainder !== verifier0) {
		return false;
	}

	let sum2 = 0;
	for (let i = 0; i <= 8; i += 1) {
		// @ts-ignore
		sum2 += digits[i] * (11 - i);
	}
	sum2 += verifier0 * 2;
	let remainder2 = sum2 % 11;
	if (remainder2 < 2) {
		remainder2 = 0;
	} else {
		remainder2 = 11 - remainder2;
		remainder2 = remainder2 === 10 ? 0 : remainder2;
	}
	if (remainder2 !== verifier1) {
		return false;
	}
	return true;
}

export function validateCnpj(s: string) {
	if (s === undefined) return false;
	const cnpj = s.replace(/[^\d]+/g, '');

	if (cnpj.length !== 14) return false;

	if (/^(\d)\1+$/.test(cnpj)) return false;

	const t = cnpj.length - 2;
	const d = cnpj.substring(t);
	const d1 = parseInt(d.charAt(0));
	const d2 = parseInt(d.charAt(1));
		//@ts-ignore
	const calc = (x) => {
		const n = cnpj.substring(0, x);
		let y = x - 7;
		let s = 0;
		let r = 0;

		for (let i = x; i >= 1; i--) {
			// @ts-ignore
			s += n.charAt(x - i) * y--;
			if (y < 2) y = 9;
		}

		r = 11 - (s % 11);
		return r > 9 ? 0 : r;
	};

	return calc(t) === d1 && calc(t + 1) === d2;
}

export function validateEmail(email: string) {
	if (
		typeof email !== 'string' ||
		email.length < 4 ||
		!email.includes('@') ||
		!email.includes('.') ||
		!email.includes('com')
	) {
		return false;
	}
	return true;
}

interface ValidarDataNascimento {
	valid: boolean;
	message: string;
}

export function validarDataNascimento(data: string): ValidarDataNascimento {
	if (typeof data !== 'string' || data.length < 10) {
		return {
			valid: false,
			message: 'Data inválida.',
		};
	}
	const regex = /^\d{2}\/\d{2}\/\d{4}$/;
	if (!regex.test(data)) {
		return {
			valid: false,
			message: 'Data inválida.',
		};
	}

	const partes = data.split('/');
	const dia = parseInt(partes[0], 10);
	const mes = parseInt(partes[1], 10);
	const ano = parseInt(partes[2], 10);

	if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
		return {
			valid: false,
			message: 'Data inválida.',
		};
	}

	if (dia < 1 || dia > 31 || mes < 1 || mes > 12) {
		return {
			valid: false,
			message: 'Data inválida.',
		};
	}

	const dataNascimento = new Date(ano, mes - 1, dia);
	if (
		dataNascimento.getFullYear() !== ano ||
		dataNascimento.getMonth() !== mes - 1 ||
		dataNascimento.getDate() !== dia
	) {
		return {
			valid: false,
			message: 'Data inválida.',
		};
	}

	// Verificar se a pessoa tem pelo menos 18 anos
	const hoje = new Date();
	const idadeMinima = new Date(
		hoje.getFullYear() - 18,
		hoje.getMonth(),
		hoje.getDate()
	);
	if (dataNascimento > idadeMinima) {
		return {
			valid: false,
			message: 'Data inválida.',
		};
	}

	return {
		valid: true,
		message: '',
	};
}

export const verificarNomeCompleto = (nomeCompleto: string) => {
	if (!nomeCompleto || typeof nomeCompleto !== 'string') {
	
		return false;
	}
	const nomes = nomeCompleto?.trim()?.split(' ');
	if (nomes.length < 2) {
		
		return false;
	}
	/*
	for (let i = 0; i < nomes.length; i++) {
		if (!nomes[i] || nomes[i].length < 2) {
			console.log('terceiro teste')
			return false;
		}
	}
	*/
	return true;
};

export const verificaEmail = (email: string) => {
	if (!email || typeof email !== 'string') {
		return false;
	}
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(email);
};

export const verificaForcaSenha = (senha: string) => {
	if (!senha || typeof senha !== 'string') {
		return false;
	}
	const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%!^&*()]).{10,}$/;
	return regex.test(senha);
};

export const telefoneMask = (senha: string) => {
	let inputValue = senha.replace(/\D/g, ''); // Remove non-numeric characters

    // Check if it's a mobile number or a landline number
    let maskedValue = '';
    if (inputValue.length <= 10) { // Mobile number
      return maskedValue = inputValue.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else { // Landline number
      maskedValue = inputValue.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }

	return maskedValue;
};


export const cepMask = (cep: string) => {
  // Remove todos os caracteres que não são dígitos
  cep = cep.replace(/\D/g, '');

  // Adiciona a máscara (XXXXX-XXX)
  cep = cep.replace(/^(\d{5})(\d)/, '$1-$2');

  return cep;
};


export const validateCreditCard = (dataCard: string) => {
	
	// Remove qualquer caractere que não seja número
	const cleaned = ('' + dataCard).replace(/\D/g, '');
  
	// Aplica a máscara MM/YY
	const formatted = cleaned.replace(/(\d{2})(\d{0,2})/, '$1/$2');
	
	// Retorna a data formatada
	return formatted;
	
  };

  export const numberCardCreditMask = (numero: string) => {
	 // Remove qualquer caractere que não seja número
	 const cleaned = ('' + numero).replace(/\D/g, '');
	 let novoNumero = '';

  if(cleaned.length > 16){
	novoNumero = cleaned.slice(0, 16);
  }else{
	novoNumero = cleaned;
  }
	 // Divide o número em blocos de 4 dígitos
	 const blocks = novoNumero.match(/.{1,4}/g);
	 
	 // Se houver blocos, junta-os com um espaço entre eles
	 if (blocks) {
	   return blocks.join(' ');
	 } else {
	   return novoNumero;
	 }
  };


