
import axios, { AxiosError } from 'axios';
import { AccesKey } from '../services/keyValidationBank';
import { AuthTokenError } from './errors/AuthTokenError';
//import * as CryptoJs from  'crypto-js';
//import CryptoJS from 'crypto-js';
import { getBase64Url  } from '../utils/helps';
import CryptoJS from 'crypto-js';
//https://apisandbox.globalpaysolucoes.com.br/api/v1',
//'https://bank.globalpaysolucoes.com.br/api/v1',
export function setupAPIClient() {
	const api = axios.create({
		baseURL: 'https://apisandboxpay.globalpaysolucoes.com.br/api/transactions/v1',
		headers: {
			token_cliente: `${AccesKey}`,
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});

	api.interceptors.response.use(
		(response) => {
			return response;
		},
		(error: AxiosError) => {
			if (error?.response?.status === 401) {
				//@ts-ignore
				//return Promise.reject(new AuthTokenError());
				setupAPIClient()
			} else {
				// Handle other errors here if needed
			}

			return Promise.reject(error);
		},
	);

	return api;
}

function getAuthToken() {
	if (typeof window !== 'undefined') {
		const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
			const [name, value] = cookie.split('=');
			// @ts-ignore
			acc[name] = value;
			return acc;
		}, {});

		// @ts-ignore
		return cookies['nextauth.token'] || '';
	}

	return '';
}

export function getAccessToken() {
	let jwtSecret = 'KxEazrWCJ13HzSzJ4QfNkhyVN4xVE8eY-tHrdfUubT0';

            // Prepare timestamp in seconds
            var currentTimestamp = Math.floor(Date.now() / 1000)

            var data = {
                issuer: 'e392cd3b-f530-4be7-b930-1921d1295968',
                iat: currentTimestamp,
                exp: currentTimestamp + (60 * 5),
                ec_client: {
                    id: 1,
                    name: 'BITHIVE SOLUCOES INTELIGENTES',
                    companyName: 'EMPRESA TESTE BITHIVE',
                    documentNumber: '33593411000197'
                }
            }

            var header = {
                'typ': 'JWT',
                'alg': 'HS256'
            };
            
            // encode header
            var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
            var encodedHeader = getBase64Url(stringifiedHeader);

            // encode data
            var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data))
            var encodedData = getBase64Url(stringifiedData)

            // build token
            var token = `${encodedHeader}.${encodedData}`;

            // sign token
            var signature = CryptoJS.HmacSHA256(token, jwtSecret);
            
            return `${token}.${getBase64Url(signature)}`;
}